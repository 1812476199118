<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ $t('COPYRIGHT') }} © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.startapp.net"
        target="_blank"
      >StartApp</b-link>
      <span class="d-none d-sm-inline-block">, {{ $t('All rights Reserved') }}</span>
    </span>

    <span class="float-md-right d-none d-md-block">{{ $t('Version') }} {{ getCurrentVersion }}
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BLink,
  },
  computed: {
    getCurrentVersion() {
      return store.state.appConfig.systemConfig !== null ? store.state.appConfig.systemConfig.currentVersion : '0.0.1'
    },
  },
}
</script>
