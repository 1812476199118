<template>
  <div v-if="respData.length>0">
    <v-select
      v-model="serviceAreaId"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="respData"
      :reduce="(respData) => respData._id"
      :placeholder="$t('Select Service Area')"
    />
  </div>

</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/store/services/hero/stateArea'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORE_MODULE_NAME = 'stateArea'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    vSelect,

  },
  computed: {
    userData() {
      return localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : { userType: 'staff' }
    },
    respData() {
      if (store.state[STORE_MODULE_NAME].respData !== null) {
        return store.state[STORE_MODULE_NAME].respData.data
      }
      return []
    },
    serviceAreaId: {
      get() {
        if (store.state[STORE_MODULE_NAME].serviceAreaId !== null) {
          return store.state[STORE_MODULE_NAME].serviceAreaId
        }
        return null
      },
      set(value) {
        store.commit(`${STORE_MODULE_NAME}/SET_AREA`, value)
        if (value !== null) {
          localStorage.setItem('serviceAreaId', value.toString())
        } else {
          localStorage.removeItem('serviceAreaId')
        }
      },
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      currentPage: 0,
      pageSize: 0,
      searchQuery: '',
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  methods: {
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
