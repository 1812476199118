<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block mr-2" />
      <dropdown-service-area
        v-if="menuService === 'hero'"
        class="d-none d-lg-block"
      />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{
                getUserData != null
                  ? `${getUserData.firstName} ${getUserData.lastName}`
                  : $t("Please Login")
              }}
            </p>
            <span class="user-status">{{ authType }}</span>
          </div>
          <b-avatar
            v-if="getUserData"
            size="40"
            variant="light-primary"
            badge
            :src="getUserData.image"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            v-else
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="menuService==='franchise'"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="ShoppingBagIcon"
            class="mr-50"
          />
          <span>{{ $t("Franchise") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="menuService==='company'"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="HomeIcon"
            class="mr-50"
          />
          <span>{{ $t("Company") }}</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t("Profile") }}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>{{ $t("Inbox") }}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>{{ $t("Task") }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="chatPage"
        >
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>{{ $t("Chat") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          v-if="getUserData != null"
          link-class="d-flex align-items-center"
          @click="logoutToType"
        >
          <feather-icon
            v-if="getUserData.userType === menuService"
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <feather-icon
            v-if="getUserData.userType !== menuService"
            size="16"
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span v-if="getUserData.userType === menuService">{{ $t("Logout") }}</span>
          <span v-if="getUserData.userType !== menuService">{{ $t("Back") }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="getUserData == null"
          link-class="d-flex align-items-center"
          @click="login"
        >
          <feather-icon
            size="16"
            icon="LogInIcon"
            class="mr-50"
          />
          <span>{{ $t("Login") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="menuService == 'franchise' || menuService == 'company' | menuService == 'delivery'| menuService == 'hero'"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{
                navbarTitle
              }}
            </p>
            <span class="user-status">{{ $t(menuService.toUpperCase()) }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="menuService == 'franchise' ? getFranchiseData.logo : menuService == 'company' ? getCompanyData.logo : menuService == 'delivery' ? getApplicationData.logo : menuService == 'hero' ? getApplicationData.logo : ''"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="getUserData != null"
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t("Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@/layouts/components/app-navbar/DarkToggler.vue'
import DropdownServiceArea from '@/layouts/components/app-navbar/DropdownServiceArea.vue' // error อะไรไม่รู้
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, rootAbility, franchiseAbility, companyAbility, deliveryAbility, heroAbility,
} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@/layouts/components/app-navbar/Locale.vue'
import NotificationDropdown from '@/layouts/components/app-navbar/NotificationDropdown.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import axios from '@axios'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,

    // Locale
    Locale,
    NotificationDropdown,
    // DropDown Service Area
    DropdownServiceArea,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    menuService() {
      return store.state.appConfig.menuService
    },
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    authType() {
      if (this.getUserData !== null) {
        if (this.getUserData.userType.toUpperCase() === this.menuService.toUpperCase()) {
          return this.$t(this.menuService.toUpperCase())
        }
        return `${this.$t(this.menuService.toUpperCase())} (${this.$t(this.getUserData.userType.toUpperCase())})`
      }
      return `${this.$t(this.menuService.toUpperCase())}`
    },
    getUserData() {
      return localStorage.getItem('userData') != null
        ? JSON.parse(localStorage.getItem('userData'))
        : null
    },
    getFranchiseData() {
      return localStorage.getItem('franchiseData') != null
        ? JSON.parse(localStorage.getItem('franchiseData'))
        : null
    },
    getCompanyData() {
      return localStorage.getItem('companyData') != null
        ? JSON.parse(localStorage.getItem('companyData'))
        : null
    },
    getApplicationData() {
      return localStorage.getItem('applicationData') != null
        ? JSON.parse(localStorage.getItem('applicationData'))
        : null
    },
  },
  methods: {
    getAbility(item, path) {
      axios.get(`api/web/getAbility/get?type=${item}`)
        .then(response => {
        // console.log(response)
          this.$ability.update(response.data.data)
          localStorage.setItem('ability', JSON.stringify(response.data.data))
          this.$router.replace({ name: path })
        })
      // eslint-disable-next-line no-unused-vars
        .catch(error => {
        // console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    chatPage() {
      // Redirect to login page
      if (this.menuService === 'hero') {
        this.$router.push({ name: 'hero-chat' })
      } else if (this.menuService === 'delivery') {
        this.$router.push({ name: 'delivery-chat' })
      } else if (this.menuService === 'company') {
        this.$router.push({ name: 'company-chat' })
      } else if (this.menuService === 'franchise') {
        this.$router.push({ name: 'franchise-chat' })
      } else if (this.menuService === 'root') {
        this.$router.push({ name: 'root-chat' })
      }
    },
    logoutToType() {
      const role = localStorage.getItem('role') ? localStorage.getItem('role') : ''
      // Redirect to login page
      if (this.getUserData.userType === 'root') {
        localStorage.removeItem('serviceAreaId')
        localStorage.removeItem('applicationId')
        localStorage.removeItem('applicationData')
        localStorage.removeItem('tokenApplication')

        // Reset ability
        // this.$ability.update(initialAbility)
        if (this.menuService === 'root') {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('serviceAreaId')

          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')
          localStorage.removeItem('companyId')
          localStorage.removeItem('companyData')
          localStorage.removeItem('franchiseId')
          localStorage.removeItem('franchiseData')

          // Reset ability
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'auth-root-login' })
        } else {
          this.$ability.update(rootAbility())
          localStorage.setItem('ability', JSON.stringify(rootAbility()))

          this.$router.replace({ name: 'root-dashboard' })
        }
      } else if (this.getUserData.userType === 'franchise') {
        localStorage.removeItem('serviceAreaId')
        localStorage.removeItem('applicationId')
        localStorage.removeItem('applicationData')
        localStorage.removeItem('tokenApplication')

        // Reset ability
        // this.$ability.update(initialAbility)
        if (this.menuService === 'franchise') {
          // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')
          localStorage.removeItem('companyId')
          localStorage.removeItem('companyData')
          localStorage.removeItem('franchiseId')
          localStorage.removeItem('franchiseData')

          // Reset ability
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'auth-root-login' })
        } else if (role === 'admin') {
          this.$ability.update(franchiseAbility())
          localStorage.setItem('ability', JSON.stringify(franchiseAbility()))
          this.$router.replace({ name: 'franchise-dashboard' })
        } else {
          this.getAbility('franchise', 'franchise-dashboard')
        }
      } else if (this.getUserData.userType === 'company') {
        localStorage.removeItem('serviceAreaId')
        localStorage.removeItem('applicationId')
        localStorage.removeItem('applicationData')
        localStorage.removeItem('tokenApplication')

        // Reset ability
        // this.$ability.update(initialAbility)
        if (this.menuService === 'company') {
          // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')
          localStorage.removeItem('companyId')
          localStorage.removeItem('companyData')
          localStorage.removeItem('franchiseId')
          localStorage.removeItem('franchiseData')

          // Reset ability
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'auth-login' })
        } else if (role === 'admin') {
          this.$ability.update(companyAbility())
          localStorage.setItem('ability', JSON.stringify(companyAbility()))
          this.$router.replace({ name: 'company-dashboard' })
        } else {
          this.getAbility('company', 'company-dashboard')
        }
      } else if (this.getUserData.userType === 'staff') {
        // this.$ability.update(initialAbility)
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('role')
        localStorage.removeItem('ability')
        localStorage.removeItem('serviceAreaId')
        localStorage.removeItem('applicationId')
        localStorage.removeItem('applicationData')
        localStorage.removeItem('tokenApplication')
        localStorage.removeItem('companyId')
        localStorage.removeItem('companyData')
        localStorage.removeItem('franchiseId')
        localStorage.removeItem('franchiseData')

        // Reset ability
        this.$ability.update(initialAbility)
        const applicationUrl = localStorage.getItem('applicationUrl') ? localStorage.getItem('applicationUrl') : ''
        console.log(applicationUrl)
        if (applicationUrl !== '') {
          this.$router.push({ path: `/app/${applicationUrl}/login` })
        } else {
          this.$router.replace({ name: 'auth-login' })
        }
      }
    },
    logout() {
      const role = localStorage.getItem('role') ? localStorage.getItem('role') : ''
      // Redirect to login page
      if (this.menuService === 'hero') {
        if (this.getUserData.userType === 'root') {
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')

          // Reset ability
          this.$ability.update(companyAbility())
          localStorage.setItem('ability', JSON.stringify(companyAbility()))

          this.$router.replace({ name: 'company-application' })
        } else if (this.getUserData.userType === 'franchise') {
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')

          // Reset ability

          this.$ability.update(companyAbility())
          localStorage.setItem('ability', JSON.stringify(companyAbility()))
          this.$router.replace({ name: 'company-application' })
        } else if (this.getUserData.userType === 'company') {
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')

          // Reset ability
          if (role === 'admin') {
            this.$ability.update(companyAbility())
            localStorage.setItem('ability', JSON.stringify(companyAbility()))
            this.$router.replace({ name: 'company-application' })
          } else {
            this.getAbility('company', 'company-application')
          }
        } else if (this.getUserData.userType === 'staff') {
          // this.$ability.update(initialAbility)
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')
          localStorage.removeItem('companyId')
          localStorage.removeItem('companyData')
          localStorage.removeItem('franchiseId')
          localStorage.removeItem('franchiseData')

          // Reset ability
          this.$ability.update(initialAbility)
          const applicationUrl = localStorage.getItem('applicationUrl') ? localStorage.getItem('applicationUrl') : ''
          console.log(applicationUrl)
          if (applicationUrl !== '') {
            this.$router.push({ path: `/app/${applicationUrl}/login` })
          } else {
            this.$router.replace({ name: 'auth-login' })
          }
        }
      } else if (this.menuService === 'delivery') {
        if (this.getUserData.userType === 'root') {
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')

          // Reset ability
          this.$ability.update(companyAbility())
          localStorage.setItem('ability', JSON.stringify(companyAbility()))

          this.$router.replace({ name: 'company-application' })
        } else if (this.getUserData.userType === 'franchise') {
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')

          // Reset ability

          this.$ability.update(companyAbility())
          localStorage.setItem('ability', JSON.stringify(companyAbility()))
          this.$router.replace({ name: 'company-application' })
        } else if (this.getUserData.userType === 'company') {
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')

          // Reset ability
          if (role === 'admin') {
            this.$ability.update(companyAbility())
            localStorage.setItem('ability', JSON.stringify(companyAbility()))
            this.$router.replace({ name: 'company-application' })
          } else {
            this.getAbility('company', 'company-application')
          }
        } else if (this.getUserData.userType === 'staff') {
          // this.$ability.update(initialAbility)
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')
          localStorage.removeItem('companyId')
          localStorage.removeItem('companyData')
          localStorage.removeItem('franchiseId')
          localStorage.removeItem('franchiseData')

          // Reset ability
          this.$ability.update(initialAbility)
          const applicationUrl = localStorage.getItem('applicationUrl') ? localStorage.getItem('applicationUrl') : ''
          console.log(applicationUrl)
          if (applicationUrl !== '') {
            this.$router.push({ path: `/app/${applicationUrl}/login` })
          } else {
            this.$router.replace({ name: 'auth-login' })
          }
        }
      } else if (this.menuService === 'company') {
        if (this.getUserData.userType === 'root') {
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')
          localStorage.removeItem('companyId')
          localStorage.removeItem('companyData')

          // Reset ability
          this.$ability.update(franchiseAbility())
          localStorage.setItem('ability', JSON.stringify(franchiseAbility()))

          this.$router.replace({ name: 'franchise-company' })
        } else if (this.getUserData.userType === 'franchise') {
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')
          localStorage.removeItem('companyId')
          localStorage.removeItem('companyData')

          // Reset ability
          if (role === 'admin') {
            this.$ability.update(franchiseAbility())
            localStorage.setItem('ability', JSON.stringify(franchiseAbility()))
            this.$router.replace({ name: 'franchise-company' })
          } else {
            this.getAbility('franchise', 'franchise-company')
          }
        } else if (this.getUserData.userType === 'company') {
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('applicationId')
          localStorage.removeItem('applicationData')
          localStorage.removeItem('tokenApplication')
          localStorage.removeItem('companyId')
          localStorage.removeItem('companyData')
          localStorage.removeItem('franchiseId')
          localStorage.removeItem('franchiseData')

          // Reset ability
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'select-company' })
        }
      } else if (this.menuService === 'franchise') {
        if (this.getUserData.userType === 'root') {
          // Reset ability
          this.$ability.update(rootAbility())
          localStorage.setItem('ability', JSON.stringify(rootAbility()))

          this.$router.replace({ name: 'root-franchise' })
        } else if (this.getUserData.userType === 'franchise') {
          // Remove userData from localStorage
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('serviceAreaId')
          localStorage.removeItem('franchiseId')
          localStorage.removeItem('franchiseData')

          // Reset ability
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'select-franchise' })
        }
      } else if (this.menuService === 'root') {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('role')
        localStorage.removeItem('ability')
        localStorage.removeItem('serviceAreaId')
        localStorage.removeItem('applicationId')
        localStorage.removeItem('applicationData')
        localStorage.removeItem('tokenApplication')
        localStorage.removeItem('companyId')
        localStorage.removeItem('companyData')
        localStorage.removeItem('franchiseId')
        localStorage.removeItem('franchiseData')

        // Reset ability
        this.$ability.update(initialAbility)
        this.$router.replace({ name: 'auth-root-login' })
        store.commit('appConfig/UPDATE_MENU_SERVICE', '')
      }
    },
    login() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('role')
      localStorage.removeItem('ability')
      localStorage.removeItem('serviceAreaId')
      localStorage.removeItem('applicationId')
      localStorage.removeItem('applicationData')
      localStorage.removeItem('tokenApplication')
      localStorage.removeItem('companyId')
      localStorage.removeItem('companyData')
      localStorage.removeItem('franchiseId')
      localStorage.removeItem('franchiseData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.replace({ name: 'auth-login' })
      store.commit('appConfig/UPDATE_MENU_SERVICE', '')
    },
  },
}
</script>
