<template>
  <feather-icon
    size="21"
    :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    @click="skin = isDark ? 'light' : 'dark'"
  />
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
