// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    serviceAreaId: localStorage.getItem('serviceAreaId') || null
    ,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    SET_AREA(state, payload) {
      state.serviceAreaId = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/serviceArea/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
